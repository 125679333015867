import React from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'
import Estilos from '../components/mainContent/container.module.css'
import ImagenDosmilUno from '../components/imageComponents/imagenDosmilUno'

const dosmilunoPpage = () => (
  <Layout>
    <SEO title='2001 Manual' />
    <Header siteTitle='OR-PRINTER Manual 2001' />

    <Container>
      <div className='row justify-content-md-center'>
        <div className='col-12 col-md-6 text-center'>
        <ImagenDosmilUno />
        </div>
        <div className='col-12 col-md-6  conmargen'>
          <h3 className={Estilos.username}>Especificaciones Técnicas:</h3>
          <ul className='aplicaciones'>
            <li>Máquina accionada por palanca manual.</li>
            <li>Alimentación eléctrica: 230 V.</li>
            <li>Poténcia eléctrica máxima: 420 W.</li>
            <li>Presión Máxima: 600 kgs.</li>
            <li>Altura máxima de la pieza: 35mm.</li>
            <li>Área máxima de impresión: 140 X 80 mm.</li>
            <li>Regulador de temperatura digital P.I.D. hasta 300&deg; C. </li>
            <li>
              Avance de película por motor eléctrico controlado por temporizador
              electrónico.
            </li>
            <li>Cabezal autobasculante, regulable en altura e inclinación.</li>
            <li>Contador digital con puesta a cero.</li>
            <li>Peso: 30 Kg.</li>
            <li>Dimensiones: Alto: 80 cms. Ancho: 44 cms. Fondo: 46 cms.</li>
          </ul>
        </div>
      </div>
    </Container>
  </Layout>
)

export default dosmilunoPpage
